import { AUTH_PATH_APP } from '@autone/ui';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { PageLayout } from '../layout';

// ---------------------- Authentication Routes --------------- //
const AuthRoutes = {
  path: AUTH_PATH_APP.root,
  layout: PageLayout,
  routes: [
    // reset password if users wanted to update it
    {
      exact: true,
      path: AUTH_PATH_APP.forgot,
      component: lazy(() => import('../views/ChangePassword')),
    },
    {
      exact: true,
      path: AUTH_PATH_APP.login,
      component: lazy(() => import('../views/Login')),
    },
    {
      exact: true,
      path: AUTH_PATH_APP.signUp,
      component: lazy(() => import('../views/SignUp')),
    },
    // send forgot password email - i.e. enter email when you have forgotten password
    {
      exact: true,
      path: AUTH_PATH_APP.reset,
      component: lazy(() => import('../views/ForgotPassword')),
    },
    {
      exact: true,
      path: '/',
      component: () => <Redirect to={AUTH_PATH_APP.login} />,
    },
  ],
};

export default AuthRoutes;
